import React from "react";
import ReactApexChart from "react-apexcharts";

class BarChart extends React.Component {
  render() {
    const { chartData, chartOptions } = this.props;

    return (
      <ReactApexChart
        options={chartOptions.options}
        series={chartData}
        type="bar"
        height="100%"
        width="100%"
      />
    );
  }
}

export default BarChart;