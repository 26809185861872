import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useColorModeValue } from "@chakra-ui/color-mode";
import OcurrencesToPart from "../components/productDetails/OcurrencesToPart";
import FailIndexByBareboneBars from "../components/productDetails/FailIndexByBareboneBars";
import FailIndexByBareboneLine from "../components/productDetails/FailIndexByBareboneLine";
import FailIndexByPartLine from "../components/productDetails/FailIndexByPartLine";
import FailIndexByBareboneAndSKULine from "../components/productDetails/FailIndexByBareboneAndSKULine";
import OcurrencesAndParqueByBareboneBarsAndLines from "../components/productDetails/OcurrencesAndParqueByBareboneBarsAndLines";
import { getGraphData } from "store/product_details/actions";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

const ProductDetails = function ({ location, ...props }) {
  
  // Imitar o pradrão para chmara a função
  const history = useHistory();
  const titleColor = useColorModeValue("secondaryGray.900", "white");
  
  const { getGraphData, infos, scores } = props;  
  useEffect(() => {
    if (history && Object.keys(infos).length === 0)
      {
        history?.push({
          pathname: "/default",
        })
      }
    }, [history, infos]);
    
    useEffect(() => {
      getGraphData();
    }, [getGraphData]);
    
  
  //let scoreOutliers = scores?.scoreOutliers;//"15,65%";
  //let scoreTendencias = scores?.scoreTendencias;//"37,50%";
  //const randomWalkPValue = "0.39%";

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid mt="10px" mb="20px">
        <Text
          pt="5px"
          me="auto"
          color={titleColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Barebone: {infos.barebone
          }
        </Text>
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">

      </SimpleGrid>
      <SimpleGrid mt="10px" mb="20px">

        <Text
          pt="1px"
          me="auto"
          fontSize="15px"
          fontWeight="700"
          lineHeight="100%"
        >
          Score para outliers: {scores.loading ? "obtendo dados...":scores?.scoreOutliers}
        </Text>
      </SimpleGrid>
      <SimpleGrid mt="10px" mb="20px">
        <Text
          pt="1px"
          me="auto"
          fontSize="15px"
          fontWeight="700"
          lineHeight="100%"
        >
          Score para tendências: {scores.loading ? "obtendo dados...":scores?.scoreTendencias}
        </Text>

      </SimpleGrid>
      

      {/* Gráfico Chamados encerrados e parque por ano, mês e barebone */}
      <OcurrencesAndParqueByBareboneBarsAndLines flexibleHeight />
      {/* Gráfico Índice de falha por barebone */}
      <FailIndexByBareboneBars flexibleHeight />
      {/* Gráfico Índice de falha por barebone II */}
      <FailIndexByBareboneLine flexibleHeight />
      {/* Gráfico Proporção de chamados com troca de peça por ano e mês */}
      <OcurrencesToPart flexibleHeight />
      {/* Gráfico Índice de falha por peça */}
      <FailIndexByPartLine flexibleHeight />
      {/* Gráfico Índice de falha por barebone */}
      <FailIndexByBareboneAndSKULine flexibleHeight />
    </Box>
  );
};

ProductDetails.propTypes = {
  infos: PropTypes.object,
  scores: PropTypes.object,
};

const mapStateToProps = (state) => ({
  infos: state.productDetails.infos,
  scores: state.productDetails.scores
});

const ProductDetailsMain = connect(mapStateToProps, { getGraphData })(ProductDetails);

export default ProductDetailsMain;
