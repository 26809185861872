import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  // MdPerson,
  MdHome,
  MdLock,
  // MdSettings,
  MdGolfCourse,
  MdUsb,
  MdTrendingUp,
  MdError,
  MdErrorOutline,
} from "react-icons/md";
import { GiPodium } from "react-icons/gi";

// Admin Imports
import DashboardMain from "views/admin/default";
import Outliers from "views/admin/outliers";
import PartToPartCoOcurrences from "views/admin/co_ocurrences/part_to_part";
import SymptomToPartCoOcurrences from "views/admin/co_ocurrences/symptom_to_part";
import CauseToPartCoOcurrences from "views/admin/co_ocurrences/cause_to_part";
import SymptomToCauseCoOcurrences from "views/admin/co_ocurrences/symptom_to_cause";
// import CasesBarebones from "views/admin/casesBarebone";
import Ranking from "views/admin/ranking";
import ProductDetails from "views/admin/productDetails";

// Auth Imports
import SignInCentered from "views/auth/signIn";
import BlackListView from "views/admin/blacklist";
import { RiProhibitedLine } from "react-icons/ri";

const routes = [
  {
    name: "Acesso",
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    hiddenInMenu: true,
  },
  {
    name: "Dashboard",
    layout: "/admin",
    path: "/default",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: DashboardMain,
  },
  {
    name: "Ranking de anormalidades",
    layout: "/admin",
    path: "/ranking",
    icon: <Icon as={GiPodium} width="20px" height="20px" color="inherit" />,
    component: Ranking,
    secondary: true,
  },
  {
    name: "Outliers",
    layout: "/admin",
    path: "/outliers",
    icon: <Icon as={MdTrendingUp} width="20px" height="20px" color="inherit" />,
    component: Outliers,
    secondary: true,
  },
  {
    name: "Peças",
    layout: "/admin",
    path: "/co_ocurrences/part_to_part",
    icon: <Icon as={MdUsb} width="20px" height="20px" color="inherit" />,
    component: PartToPartCoOcurrences,
    secondary: true,
  },
  {
    name: "Sintoma vs peça",
    layout: "/admin",
    path: "/co_ocurrences/symptom_to_part",
    icon: (
      <Icon as={MdErrorOutline} width="20px" height="20px" color="inherit" />
    ),
    component: SymptomToPartCoOcurrences,
    secondary: true,
  },
  {
    name: "Causa vs peça",
    layout: "/admin",
    path: "/co_ocurrences/cause_to_part",
    icon: <Icon as={MdError} width="20px" height="20px" color="inherit" />,
    component: CauseToPartCoOcurrences,
    secondary: true,
  },
  {
    name: "Sintoma vs causa",
    layout: "/admin",
    path: "/co_ocurrences/symptom_to_cause",
    icon: <Icon as={MdGolfCourse} width="20px" height="20px" color="inherit" />,
    component: SymptomToCauseCoOcurrences,
    secondary: true,
  },
  {
    name: "Blacklist",
    layout: "/admin",
    path: "/blacklist",
    icon: (
      <Icon as={RiProhibitedLine} width="20px" height="20px" color="inherit" />
    ),
    component: BlackListView,
  },
  /*
  {
    name: "Ocorrência de peças",
    layout: "/admin",
    icon: <Icon as={MdBuildCircle} width='20px' height='20px' color='inherit' />,
    path: "/occurrences",
    component: Occurrences,
  },
  {
    name: "Configurações",
    layout: "/admin",
    path: "/settings",
    icon: <Icon as={MdSettings} width='20px' height='20px' color='inherit' />,
    component: Settings,
  },
  {
    name: "Perfil",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: Profile,
  },
  */
  {
    name: "Detalhes do barebone",
    layout: "/admin",
    path: "/productDetails",
    hiddenInMenu: true,
    component: ProductDetails,
  },
];

export default routes;
