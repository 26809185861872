import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  infos:{},
  scores:{},
  sku: {
    loading: false,
    items: []
  },
  serie_idf: {
    loading: false,
    items: []
  },
  series_ifp: {
    loading: false,
    items: []
  },
  serie_chamados_vendas: {
    loading: false,
    items: {}
  },
  serie_proporcao_c_peca: {
    loading: false,
    items: []
  }
};

const productDetailsSlice = createSlice({
  name: "productDetails",
  initialState,
  reducers: {
    setInfos: (state, action) => {
      state.infos = action.payload;
    },
    setLoadingScores: (state, action) => {
      state.scores.loading = action.payload;
    },
    getproductDetailsScoresSuccessful: (state, action) => {
      state.scores = action.payload;
    },
    getproductDetailsScoresError: (state) => {
      state.scores = {};
    },

    setLoadingSku: (state, action) => {
      state.sku.loading = action.payload;
    },
    getproductDetailsSkuSuccessful: (state, action) => {
      state.sku.items = action.payload;
    },
    getproductDetailsSkuError: (state) => {
      state.sku.items = [];
    },

    setLoadingSeriesIfp: (state, action) => {
      state.series_ifp.loading = action.payload;
    },
    getproductDetailsSeriesIfpSuccessful: (state, action) => {
      state.series_ifp.items = action.payload;
    },
    getproductDetailsSeriesIfpError: (state) => {
      state.series_ifp.items = [];
    },

    setLoadingProporcaoCPeca: (state, action) => {
      state.serie_proporcao_c_peca.loading = action.payload;
    },
    getproductDetailsProporcaoCPecaSuccessful: (state, action) => {
      state.serie_proporcao_c_peca.items = action.payload;
    },
    getproductDetailsProporcaoCPecaError: (state) => {
      state.serie_proporcao_c_peca.items = [];
    },

    setLoadingIdfPeca: (state, action) => {
      state.serie_idf.loading = action.payload;
    },
    getproductDetailsIdfPecaSuccessful: (state, action) => {
      state.serie_idf.items = action.payload;
    },
    getproductDetailsIdfPecaError: (state) => {
      state.serie_idf.items = [];
    },
    
    setLoadingChamadosVendas: (state, action) => {
      state.serie_chamados_vendas.loading = action.payload;
    },
    getproductDetailsChamadosVendasSuccessful: (state, action) => {
      state.serie_chamados_vendas.items = action.payload;
    },
    getproductDetailsChamadosVendasError: (state) => {
      state.serie_chamados_vendas.items = {};
    },

    setLoadingIdfSintoma: (state, action) => {
      state.sku.loading = action.payload;
    },
    getproductDetailsIdfSintomaSuccessful: (state, action) => {
      state.sku.items = action.payload;
    },
    getproductDetailsIdfSintomaError: (state) => {
      state.sku.items = [];
    },
  },
});

export const {
  setInfos,
  setLoadingScores,
  getproductDetailsScoresSuccessful,
  getproductDetailsScoresError,
  setLoadingSku,
  getproductDetailsSkuSuccessful,
  getproductDetailsSkuError,
  setLoadingSeriesIfp,
  getproductDetailsSeriesIfpSuccessful,
  getproductDetailsSeriesIfpError,
  setLoadingProporcaoCPeca,
  getproductDetailsProporcaoCPecaSuccessful,
  getproductDetailsProporcaoCPecaError,
  setLoadingChamadosVendas,
  getproductDetailsChamadosVendasSuccessful,
  getproductDetailsChamadosVendasError,
  setLoadingIdfPeca,
  getproductDetailsIdfPecaSuccessful,
  getproductDetailsIdfPecaError,
  setLoadingIdfSintoma,
  getproductDetailsIdfSintomaSuccessful,
  getproductDetailsIdfSintomaError,
} = productDetailsSlice.actions;

export default productDetailsSlice.reducer;
