import axios from "axios";
import _ from "lodash";
import { 
  setInfos,
  setLoadingSku,
  getproductDetailsSkuSuccessful,
  getproductDetailsSkuError,
  setLoadingScores,
  getproductDetailsScoresSuccessful,
  getproductDetailsScoresError,
  setLoadingSeriesIfp,
  getproductDetailsSeriesIfpSuccessful,
  getproductDetailsSeriesIfpError,
  setLoadingChamadosVendas,
  getproductDetailsChamadosVendasSuccessful,
  getproductDetailsChamadosVendasError,
  setLoadingProporcaoCPeca,
  getproductDetailsProporcaoCPecaSuccessful,
  getproductDetailsProporcaoCPecaError,

} from "./slice";
import { toast } from "react-toastify";
import { API_ROUTE_GRAPHICS_IDF_SKU } from "api/routes";
import { API_ROUTE_GRAPHICS_PROPORCAO_C_PECA } from "api/routes";
import { API_ROUTE_GRAPHICS_CHAMADOS_PARQUE } from "api/routes";
import { API_ROUTE_GRAPHICS_IDF_PECA } from "api/routes";
import { API_ROUTE_GRAPHICS_IDF_OUTLIERS } from "api/routes";

function calculaScoreSerie(data) {
  const n = data.length;
  
  // Criar os pesos
  const e = Array.from({ length: n }, (_, i) => n - i - 1); // Equivalente a np.flip(np.arange(n))
  const weights = e.map(value => 1 / Math.pow(2, value)); // 1 / (2 ** e)

  // Calcular o score
  const score = data.reduce((sum, value, index) => sum + value * weights[index], 0);

  return `${(score)}`;
}



export const getproductDetailsScores = () => (dispatch, getState) => {
  dispatch(setLoadingScores(true));
  const { startDate, endDate,} = getState().filters;
  const { barebone, segment, product, brand } = getState().productDetails.infos;
  

  axios
    .post(API_ROUTE_GRAPHICS_IDF_OUTLIERS, 
      {
        segment,
        product,
        brand,
        barebone,
        barebone: barebone,
        date: [startDate?.split("/").reverse().join("-"),  endDate?.split("/").reverse().join("-")],
      }
    )
    .then((response) => {
      dispatch(getproductDetailsScoresSuccessful(
        {
          scoreOutliers: calculaScoreSerie(response?.data?.data?.barebone?.idf_outliers),
          scoreTendencias: calculaScoreSerie(response?.data?.data?.barebone?.idf_tendencias),
        }
      )); 
    })
    .catch((error) => {
      dispatch(getproductDetailsScoresError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de SKU. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingScores(false));
    });
};

export const getproductDetailsSku = () => (dispatch, getState) => {
  dispatch(setLoadingSku(true));
  const { startDate, endDate,} = getState().filters;
  const { barebone, segment, product, brand } = getState().productDetails.infos;
  

  axios
    .post(API_ROUTE_GRAPHICS_IDF_SKU, 
      {
        segment,
        product,
        brand,
        barebone,
        barebone: barebone,
        date: [startDate?.split("/").reverse().join("-"),  endDate?.split("/").reverse().join("-")],
      }
    )
    .then((response) => {
      dispatch(getproductDetailsSkuSuccessful(response?.data?.data?.sku));
    })
    .catch((error) => {
      dispatch(getproductDetailsSkuError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de SKU. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingSku(false));
    });
};

export const getproductDetailsSeriesIfp = () => (dispatch, getState) => {
  dispatch(setLoadingSeriesIfp(true));
  const { startDate, endDate} = getState().filters;
  const { barebone, segment, product, brand } = getState().productDetails.infos;
  
  axios
    .post(API_ROUTE_GRAPHICS_IDF_PECA, 
      {
        segment,
        product,
        brand,
        barebone,
        barebone: barebone,
        date: [startDate?.split("/").reverse().join("-"),  endDate?.split("/").reverse().join("-")],
      }
    )
    .then((response) => {
      dispatch(getproductDetailsSeriesIfpSuccessful(response?.data?.data?.barebone?.series_ifp));
    })
    .catch((error) => {
      dispatch(getproductDetailsSeriesIfpError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de falha por peça. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingSeriesIfp(false));
    });
};

export const getproductDetailsProporcaoCPeca = () => (dispatch, getState) => {
  dispatch(setLoadingProporcaoCPeca(true));
  const { startDate, endDate} = getState().filters;
  const { barebone, segment, product, brand } = getState().productDetails.infos;
  

  axios
    .post(API_ROUTE_GRAPHICS_PROPORCAO_C_PECA, 
      {
        segment,
        product,
        brand,
        barebone,
        date: [startDate?.split("/").reverse().join("-"),  endDate?.split("/").reverse().join("-")],
      }
    )
    .then((response) => {
      dispatch(getproductDetailsProporcaoCPecaSuccessful(response?.data?.data?.barebone?.serie_proporcao_c_peca));
    })
    .catch((error) => {
      dispatch(getproductDetailsProporcaoCPecaError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de proporção com peça. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingProporcaoCPeca(false));
    });
};


export const getproductDetailsChamadosVendas = () => (dispatch, getState) => {
  dispatch(setLoadingChamadosVendas(true));
  const { startDate, endDate, } = getState().filters;
  const { barebone, segment, product, brand } = getState().productDetails.infos;
  

  axios
    .post(API_ROUTE_GRAPHICS_CHAMADOS_PARQUE, 
      {
        segment,
        product,
        brand,
        barebone,
        barebone: barebone,
        date: [startDate?.split("/").reverse().join("-"),  endDate?.split("/").reverse().join("-")],
      }
    )
    .then((response) => {
      dispatch(getproductDetailsChamadosVendasSuccessful(response?.data?.data.barebone));
    })
    .catch((error) => {
      dispatch(getproductDetailsChamadosVendasError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de Parque e Chamados. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingChamadosVendas(false));
    });
};


export const getproductDetailsIdfPeca = () => (dispatch, getState) => {
  dispatch(setLoadingSku(true));
  const { startDate, endDate, } = getState().filters;
  const { barebone, segment, product, brand } = getState().productDetails.infos;

  axios
    .post(API_ROUTE_GRAPHICS_IDF_SKU, 
      {
        segment,
        product,
        brand,
        barebone,
        barebone: barebone,
        date: [startDate?.split("/").reverse().join("-"),  endDate?.split("/").reverse().join("-")],
      }
    )
    .then((response) => {
      dispatch(getproductDetailsSkuSuccessful(response?.data?.data?.sku));
    })
    .catch((error) => {
      dispatch(getproductDetailsSkuError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de SKU. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingSku(false));
    });
};

export const getproductDetailsIdfSintoma = () => (dispatch, getState) => {
  dispatch(setLoadingSku(true));
  const { startDate, endDate, barebones, segments } = getState().filters;
  const { barebone, segment, product, brand } = getState().productDetails.infos;

  axios
    .post(API_ROUTE_GRAPHICS_IDF_SKU, 
      {
        segment,
        product,
        brand,
        barebone,
        barebone: barebone,
        date: [startDate?.split("/").reverse().join("-"),  endDate?.split("/").reverse().join("-")],
      }
    )
    .then((response) => {
      dispatch(getproductDetailsSkuSuccessful(response?.data?.data?.sku));
    })
    .catch((error) => {
      dispatch(getproductDetailsSkuError(error.response?.data));
      toast.error(
        "Não foi possível obter as informações de SKU. Tente novamente mais tarde."
      );
    })
    .finally(() => {
      dispatch(setLoadingSku(false));
    });
};

export const setProductDetailsInfos = (barebone) => (dispatch, getState) => {
  dispatch(setInfos(barebone));
};

export const getGraphData = () => (dispatch) => {
  dispatch(getproductDetailsSku());
  dispatch(getproductDetailsSeriesIfp());
  dispatch(getproductDetailsProporcaoCPeca());
  dispatch(getproductDetailsChamadosVendas());
  dispatch(getproductDetailsScores());
  //dispatch(getproductDetailsIdfPeca());
  //dispatch(getproductDetailsIdfSintoma());
};