// Auth
export const API_ROUTE_TOKEN = "auth/token/";
export const API_ROUTE_REFRESH_TOKEN = "auth/token/refresh/";
export const API_ROUTE_PROFILE = "users/me/";

// Filters
export const API_ROUTE_FILTERS_BAREBONES = "filters/barebones/";
export const API_ROUTE_FILTERS_SEGMENTS = "filters/segments/";

// Outliers
export const API_ROUTE_OUTLIERS = "outliers/";

// Co-ocurrences
export const API_ROUTE_CO_OCURRENCES_PART_TO_PART = "co_ocurrences/part_to_part/";
export const API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_PART = "co_ocurrences/symptom_to_part/";
export const API_ROUTE_CO_OCURRENCES_CAUSE_TO_PART = "co_ocurrences/cause_to_part/";
export const API_ROUTE_CO_OCURRENCES_SYMPTOM_TO_CAUSE = "co_ocurrences/symptom_to_cause/";

// Ranking
export const API_ROUTE_RANKING = "ranking/";

// blacklists
export const API_ROUTE_BLACKLIST = "blacklist/";

// graphics

export const API_ROUTE_GRAPHICS_CHAMADOS_PARQUE = "graphics/chamados_parque_idf/";
export const API_ROUTE_GRAPHICS_IDF_OUTLIERS = "graphics/idf_outliers/";
export const API_ROUTE_GRAPHICS_PROPORCAO_C_PECA = "graphics/proporcao_c_peca/";
export const API_ROUTE_GRAPHICS_IDF_PECA = "graphics/idf_peca/";
export const API_ROUTE_GRAPHICS_IDF_SINTOMA = "graphics/idf_sintoma/";
export const API_ROUTE_GRAPHICS_IDF_SKU = "graphics/idf_sku/";