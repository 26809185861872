export const convertFloatToPercentage = (float) => {
  // Multiply the float by 100 to get the percentage value
  const percentage = float * 100;
  // Use the toLocaleString() method to add commas as thousand separators
  const formattedPercentage = percentage.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  // Append the percentage symbol
  const percentageString = formattedPercentage + "%";
  return percentageString;
}

export const roundFloat = (float) => {
  // Use the toLocaleString() method to add commas as thousand separators
  const formatted = float.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${formatted}`;
}

export const parseDate = (item) => {
  const y = item[0];
  const m = item[1].toString().padStart(2, 0);
  const date = `${y}-${m}-01T00:00:00Z`
  return date;
}
